import {ErrorHandler, Inject, Injectable, Injector} from '@angular/core';
import {AppInsightsLoggingService, GeneralUrls} from '@mptl/web/services';
import {BaseResponse, ErrorEnum, IRootState, SlackException} from "@mptl/models";
import {map, take} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {SeverityLevel} from "@microsoft/applicationinsights-web";

@Injectable()
export class AppErrorHandler extends ErrorHandler {

  public errors: string[] = [ErrorEnum.PermissionDenied, ErrorEnum.ChunkLoadError, ErrorEnum.ReadPermissionDenied,
    ErrorEnum.RequestedDeviceNotFound, ErrorEnum.NoValidDataOnClipboard, ErrorEnum.NotAllowedError, ErrorEnum.Initialise,
    ErrorEnum.TypeErrorQuery, ErrorEnum.NotReadableError, ErrorEnum.CouldNotStartVideoSource, ErrorEnum.CantFindVariableNotification]

  constructor(
    private appInsightsLoggingService: AppInsightsLoggingService,
    private injector: Injector,
    @Inject('BASE_URL') private baseUrl: any,
    @Inject('ENVIRONMENT_NAME')
    private environmentName: 'local' | 'development' | 'production',
    private http: HttpClient
  ) {
    super();
  }

  handleError(error: Error) {
    if (!this.errors.some(s => error?.message?.includes(s))) {
      this.appInsightsLoggingService.logException(error, SeverityLevel.Critical);
      // const rootStore = this.injector.get(Store);
      // rootStore.pipe(select(selectUserProfile), take(1)).subscribe(user => {
      const rootStoreString = localStorage.getItem('root');
      let userName = '';
      if (rootStoreString) {
        try {
          const root: IRootState = JSON.parse(rootStoreString);
          userName = (root?.user?.firstName ?? '') + ' ' + (root?.user?.lastName ?? '');
        } catch (e) {
          console.log(e);
        }
      }
      if (error?.name || error?.message || error?.stack) {
        const exception = new SlackException();
        exception.type = "window";
        exception.userName = userName;
        exception.errorMessage = typeof error?.message === 'string' ? error?.message : 'Not Available';
        exception.lineno = 0;
        exception.source = '';
        exception.colno = 0;
        exception.name = error?.name;
        exception.description = error?.message;
        exception.data = error?.stack;
        exception.componentName = 'AppErrorHandler';
        exception.functionName = 'Not Available';
        this.postSlackMessage(exception).pipe(take(1)).subscribe(() => {
        }, () => {
        })
      }
      // })
    }
    super.handleError(error);
  }

  postSlackMessage(
    slackException: SlackException
  ): Observable<BaseResponse<string, SlackException>> {
    if (this.environmentName !== 'local') {
      return this.http
        .post<BaseResponse<string, SlackException>>(GeneralUrls.sendSlackMessage(this.baseUrl), {message: slackException?.toString()})
        .pipe(
          map((response: BaseResponse<string, SlackException>) => {
            response.request = slackException;
            return response;
          })
        );
    } else {
      const data: BaseResponse<string, SlackException> = new BaseResponse<string, SlackException>();
      data.request = slackException;
      data.status = 'success';
      data.errors = [];
      data.data = '';
      data.hasError = false;
      data.queryString = '';
      return of(data)
    }
  }
}
