export const environment = {
  production: true,
  apiUrl: 'https://mptl-api-prod.azurewebsites.net/api/',
  appUrl: 'https://www.mypathtolearning.com/',
  environmentName: 'production',
  appInsights: {
    instrumentationKey: '8b666c17-db61-4e68-83b2-bbabae3da169',
  },
  version: "2.1.5"
};
