import {ApplicationRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AppInsightsLoggingService, GeneralService, ReleaseNoteService} from '@mptl/web/services';
import {select, Store} from '@ngrx/store';
import {distinctUntilChanged, first, take, takeUntil} from 'rxjs/operators';
import {BaseComponent, IAppState, selectUserProfile, UserProfile} from '@mptl/models';
import {Title} from '@angular/platform-browser';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent
} from 'ngx-cookieconsent';
import {AppInstallNowComponent} from '@mptl/web/ui/app-install-now';
import {SwUpdate} from '@angular/service-worker';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {isEqual} from 'lodash-es';
import {concat, interval} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

//zenDesk
// declare const zE: any;

@Component({
  selector: 'mptl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  userName = '';
  userId = 0;
  token = '';

  constructor(
    private appInsightsLoggingService: AppInsightsLoggingService,
    private store: Store<IAppState>,
    private router: Router,
    private title: Title,
    private swUpdate: SwUpdate,
    public generalService: GeneralService,
    public appRef: ApplicationRef,
    private _bottomSheet: MatBottomSheet,
    @Inject('ENVIRONMENT_NAME')
    private environmentName: 'local' | 'development' | 'production',
    private ccService: NgcCookieConsentService,
    private releaseNoteService: ReleaseNoteService,
    public dialog: MatDialog
  ) {
    super();
  }

  updateApp() {
    console.log('update App start');
    if (this.generalService.isStandalone) {
      this._bottomSheet
        .open(AppInstallNowComponent, {
          disableClose: true,
          hasBackdrop: true,
          data: {isUpdateMode: true}
        })
        .afterDismissed()
        .pipe(take(1))
        .subscribe(() => {
          this.swUpdate.activateUpdate().then(() => (window.location as any).reload());
        });
    } else {
      alert('A new version has been detected and will be updated.');
      this.swUpdate.activateUpdate().then(() => (window.location as any).reload());
      // this.swUpdate.activateUpdate().then(() => {
      //   console.log('checking for version');
      //   const localVersion = localStorage.getItem('appVersion');
      //   let currentVersion = '';
      //   this.releaseNoteService.getCurrentVersionNo().subscribe(res => {
      //     if(res){
      //       currentVersion = res.data;
      //       if (!localVersion ) {
      //         console.log('localVersion not found in local' + currentVersion);
      //         localStorage.setItem('appVersion', currentVersion);
      //         this.releaseNoteService.getReleaseNoteByVersion(currentVersion).subscribe(res => {
      //           if (res) {
      //             this.dialog.open(ViewReleaseNoteComponent, {
      //               panelClass: 'release-center-model',
      //               data: {
      //                 releaseNote: res.data.notes,
      //               },
      //               hasBackdrop: true,
      //               disableClose: true
      //             }).afterClosed().subscribe(p => {
      //                 (window.location as any).reload();
      //             });
      //           }else{
      //             (window.location as any).reload();
      //           }
      //         });
      //       }
      //       else if(localVersion != currentVersion){
      //         console.log('localVersion not match' + currentVersion);
      //         localStorage.setItem('appVersion', currentVersion);
      //         this.releaseNoteService.getReleaseNoteByVersion(currentVersion).subscribe(res => {
      //           if (res) {
      //             this.dialog.open(ViewReleaseNoteComponent, {
      //               panelClass: 'release-center-model',
      //               data: {
      //                 releaseNote: res.data.notes,
      //               },
      //               hasBackdrop: true,
      //               disableClose: true
      //             }).afterClosed().subscribe(p => {
      //                 (window.location as any).reload();
      //             });
      //
      //           } else{
      //             (window.location as any).reload();
      //           }
      //         });
      //       }
      //       else{
      //         (window.location as any).reload();
      //       }
      //     }
      //   });
      // });
    }
  }

  ngOnInit() {
    this.appRef.isStable.pipe(first(s => s === true), take(1)).subscribe(res => {
      console.log('APP is stable.');
      this.appInsightsLoggingService.initAppInsight();
      this.router.events.pipe(takeUntil(this.destroy$)).subscribe((res) => {
        if (res instanceof NavigationEnd) {
          this.appInsightsLoggingService.logPageView(
            this.title.getTitle(),
            res.url,
            {
              appName: 'MPTL-WEB-' + this.environmentName,
              userName: this.userName,
              userId: this.userId
            }
          );
        }
      });
      this.store
        .pipe(
          select(selectUserProfile),
          takeUntil(this.destroy$),
          distinctUntilChanged(isEqual)
        )
        .subscribe((res: UserProfile | null) => {
          if (res && res.id) {
            this.userName = res.firstName + ' ' + res.lastName;
            this.userId = res.id;
            this.appInsightsLoggingService.addLoggedInUser(
              res.firstName + ' ' + res.lastName,
              res.id?.toString()
            );

            // if (!res.isStudent) {
            //   zE("messenger:set", "cookies", true);
            // }

          } else {
            this.userId = 0;
            this.userName = '';
            // zE("messenger:set", "cookies", false);
          }
        });
        navigator?.serviceWorker?.getRegistration()?.then(data => {
          if (data) {
            const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
            const every2Minute$ = interval(120 * 1000);
            const every2MinuteOnceAppIsStable$ = concat(appIsStable$, every2Minute$);
            every2MinuteOnceAppIsStable$.pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.swUpdate.checkForUpdate().then();
            });
            every2MinuteOnceAppIsStable$.pipe(take(1)).subscribe(res => {
              this.swUpdate.unrecoverable.subscribe(event => {
                alert(
                  'An error occurred that we cannot recover from:\n' +
                  event.reason +
                  '\n\nPlease reload the page.'
                );
                (window.location as any).reload(true);
              });
              this.swUpdate.versionUpdates.subscribe(evt => {
                switch (evt.type) {
                  case 'VERSION_DETECTED':
                    console.log(`Downloading new app version: ${evt.version.hash}`);
                    break;
                  case 'VERSION_READY':
                    this.updateApp();
                    break;
                  case 'VERSION_INSTALLATION_FAILED':
                    console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                    break;
                }
              });
            });
          }
        });
      window.addEventListener('beforeinstallprompt', (event: any) => {
        console.log('beforeinstallprompt', event);
        event.preventDefault();
        this.generalService.promptEvent.next(event);
      });

      if ('getInstalledRelatedApps' in navigator) {
        const url = window.location.protocol ? ((window.location.protocol) + '://') : '' + window.location.host + '/manifest.webmanifest';
        (navigator as any).getInstalledRelatedApps().then((apps: any[]) => {
          const isInstalled = apps.length ? apps.some(s => s.url === url) : false;
          if (isInstalled) {
            this.generalService.appInstalled.next(true);
          }
        });
      }
      window.addEventListener('appinstalled', (event) => {
        this.generalService.appInstalled.next(true);
      });
    });
    this.ccCheck();
  }

  ccCheck() {
    const accepted = (JSON.parse(localStorage.getItem('cookie-consents') ?? 'false'));

    if (!accepted) {
      this.ccService.open();
    }
    this.ccService.popupOpen$.pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.ccService.popupClose$.pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.ccService.initialize$.pipe(takeUntil(this.destroy$)).subscribe(
      (event: NgcInitializeEvent) => {
        //
      });

    this.ccService.statusChange$.pipe(takeUntil(this.destroy$)).subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.status === 'allow') {
          localStorage.setItem('cookie-consents', JSON.stringify(true));
          this.ccService.close(false);
        }
      });

    this.ccService.revokeChoice$.pipe(takeUntil(this.destroy$)).subscribe(
      () => {
      });

    this.ccService.noCookieLaw$.pipe(takeUntil(this.destroy$)).subscribe(
      (event: NgcNoCookieLawEvent) => {
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}

