import { Action, createReducer, on } from '@ngrx/store';
import {
  ChangeTreeState,
  ChangeTreeStateFromMoveToDirectory,
  DontShowHelpPopup,
  FirstTestCreatedCompleted,
  IRootState,
  LoginCompleteAction,
  LoginErrorAction,
  LogoutAction,
  RemovePracticeTestByIdDrawingPadLocalSettings,
  ResetErrorAction,
  SetAssignmentRequestSettings,
  SetCanRotateDevice,
  SetCanUseStudentApp,
  SetChatAdmin,
  SetClipBoardPermission,
  SetDashboardRecordSetting,
  SetFinalGradeDateRange,
  SetFluencyResultsSettings,
  SetGradingResultsDateRange,
  SetHasUnReadChats,
  SetNotificationPermission, SetPermissionAsked,
  SetPracticeTestDrawingPadLocalSettings,
  SetPracticeTestStudentWordSettings,
  SetSessionUserData,
  SetStudentCode,
  SetTestGradeRecordSetting,
  SetUserAction
} from '@mptl/models';
import { cloneDeep } from 'lodash-es';

const initialState: IRootState = {
  token: '',
  user: null,
  externalLoginInProcess: false,
  userLocalConfigs: {
    dashboardRecordsSettings: {},
    testGradeRecordsSettings: {},
    treeState: {},
    treeStateFromMoveToDirectory: {},
    practiceTestStudentWordSettings: {},
    fluencyResultsSettings: {},
    assignmentRequest: undefined,
    practiceTestDrawingPadLocalSettings: {}
  },
  errors: [],
  studentCode: '',
  chatAdmin: null,
  notificationPermission: null,
  clipboardPermission: null,
  permissionAsked: false
};

export function RootReducer(state: IRootState = initialState, action: Action) {
  return _rootReducer(state, action);
}

export const canUseStudentAppReducer = createReducer(
  undefined,
  on(SetCanUseStudentApp, (state, action) => action.state)
);

export const canRotateDeviceReducer = createReducer(
  undefined,
  on(SetCanRotateDevice, (state, action) => action.state)
);

export const canFinalGradeDateRangeReducer = createReducer(
  undefined,
  on(SetFinalGradeDateRange, (state, action) => action.state)
);

export const canGradingResultsDateRangeReducer = createReducer(
  undefined,
  on(SetGradingResultsDateRange, (state, action) => action.state)
);

export const showHelpPopup = createReducer(
  true,
  on(DontShowHelpPopup, (state, action) => false)
);

export const firstTestCreated = createReducer(undefined, on(FirstTestCreatedCompleted, (state) => true));
export const sessionUserData = createReducer(
  undefined,
  on(SetSessionUserData, (state, action) => action.response)
);

const _rootReducer = createReducer(
  initialState,
  on(LoginErrorAction, (state, { errors }) => {
    return {
      ...state,
      user: null,
      token: null,
      externalLoginInProcess: false,
      errors: errors
    };
  }),
  on(LoginCompleteAction, (state, { response }) => {
    return {
      ...state,
      user: response.user,
      token: response.token,
      userLocalConfigs: {
        assignmentRequest: undefined,
        dashboardRecordsSettings: {},
        testGradeRecordsSettings: {},
        treeState: {},
        treeStateFromMoveToDirectory: {},
        practiceTestStudentWordSettings: {},
        fluencyResultsSettings: {},
        practiceTestDrawingPadLocalSettings: {}
      },
      canUseStudentApp: undefined,
      externalLoginInProcess: false
    };
  }),
  on(LogoutAction, (state) => {
    return {
      ...state,
      token: '',
      user: null,
      userLocalConfigs: {
        assignmentRequest: undefined,
        dashboardRecordsSettings: {},
        testGradeRecordsSettings: {},
        treeState: {},
        treeStateFromMoveToDirectory: {},
        practiceTestStudentWordSettings: {},
        fluencyResultsSettings: {},
        practiceTestDrawingPadLocalSettings: {}
      },
      canUseStudentApp: undefined,
      externalLoginInProcess: false
    };
  }),
  on(SetDashboardRecordSetting, (state, { id, value }) => {
    return {
      ...state,
      userLocalConfigs: {
        ...state.userLocalConfigs,
        dashboardRecordsSettings: {
          ...state.userLocalConfigs.dashboardRecordsSettings,
          [id]: value
        }
      }
    };
  }),
  on(SetTestGradeRecordSetting, (state, { id, value }) => {
    return {
      ...state,
      userLocalConfigs: {
        ...state.userLocalConfigs,
        testGradeRecordsSettings: {
          ...state.userLocalConfigs.testGradeRecordsSettings,
          [id]: value
        }
      }
    };
  }),
  on(SetPracticeTestStudentWordSettings, (state, { id, value }) => {
    return {
      ...state,
      userLocalConfigs: {
        ...state.userLocalConfigs,
        practiceTestStudentWordSettings: {
          ...state.userLocalConfigs.practiceTestStudentWordSettings,
          [id]: value
        }
      }
    };
  }),
  on(SetFluencyResultsSettings, (state, { id, value }) => {
    return {
      ...state,
      userLocalConfigs: {
        ...state.userLocalConfigs,
        fluencyResultsSettings: {
          ...state.userLocalConfigs.fluencyResultsSettings,
          [id]: value
        }
      }
    };
  }),
  on(SetAssignmentRequestSettings, (state, { request }) => {
    return {
      ...state,
      userLocalConfigs: {
        ...state.userLocalConfigs,
        assignmentRequest: request
      }
    };
  }),
  on(ResetErrorAction, (state) => {
    return {
      ...state,
      errors: []
    };
  }),
  on(SetUserAction, (state, { user }) => {
    return {
      ...state,
      user: user
    };
  }),
  on(SetStudentCode, (state, { studentCode }) => {
    return { ...state, studentCode: studentCode };
  }),
  on(ChangeTreeState, (state, { treeState }) => {
    return { ...state, userLocalConfigs: { ...state.userLocalConfigs, treeState: { ...treeState } } };
  }),
  on(ChangeTreeStateFromMoveToDirectory, (state, { treeState }) => {
    return {
      ...state,
      userLocalConfigs: { ...state.userLocalConfigs, treeStateFromMoveToDirectory: { ...treeState } }
    };
  }),
  on(SetPracticeTestDrawingPadLocalSettings, (state, { data }) => {
    return {
      ...state,
      userLocalConfigs: {
        ...state.userLocalConfigs,
        practiceTestDrawingPadLocalSettings: { ...state.userLocalConfigs.practiceTestDrawingPadLocalSettings, ...data }
      }
    };
  }),
  on(RemovePracticeTestByIdDrawingPadLocalSettings, (state, { testId }) => {
    const localData = cloneDeep(state.userLocalConfigs.practiceTestDrawingPadLocalSettings);
    delete localData[testId];
    return {
      ...state,
      userLocalConfigs: {
        ...state.userLocalConfigs,
        practiceTestDrawingPadLocalSettings: localData
      }
    };
  }),
  on(SetHasUnReadChats, (state, { hasUnReadChats }) => {
    return {
      ...state,
      user: state.user ? {
        ...state.user,
        hasUnReadChats
      } : state.user
    };
  }),

  on(SetChatAdmin, (state, { chatAdmin }) => {
    return {
      ...state,
      chatAdmin: chatAdmin
    };
  }),

  on(SetNotificationPermission, (state, { data }) => {
    return {
      ...state,
      notificationPermission: data
    };
  }),
  on(SetClipBoardPermission, (state, { data }) => {
    return {
      ...state,
      clipboardPermission: data
    };
  }),
  on(SetPermissionAsked, (state, { data }) => {
    return {
      ...state,
      permissionAsked: data
    };
  })
);

